.burger {
    $this: &;
    position: relative;
    width: 20px;
    height: 18px;

    &-lines {
        position: relative;
        width: 100%;
        height: 100%;

        &__top, &__center, &__bottom {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $color-text;
            margin: auto 0;
            transition: transform 200ms ease, opacity 200ms ease;
        }

        &__top {
            transform: translateY(-8px);
        }

        &__bottom {
            transform: translateY(8px);
        }
    }

    .mobile-menu-button.opened &-lines {
        &__top {
            transform: translateY(0) rotate(45deg);
        }

        &__center {
            transform: translateX(-5px) scale(0.8);
            opacity: 0;
        }

        &__bottom {
            transform: translateY(0) rotate(-45deg);
        }
    }
}