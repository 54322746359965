.form-group{
	margin: 0 0 10px;
	&.required{
		label{
			position: relative;
			&:after{
				content: "*";
				color: #d9534f;
			}
		}
	}
	label{
		margin: 0 0 5px;
	}
	textarea{
		height: 135px;
	}
}