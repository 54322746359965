.notification-block{
	display: flex;
	flex-direction: row;
	border-radius: 5px;
    border: 1px solid #E5E5E5;
    background-color: #fff;
    padding: 20px;
    &__icon{
    	margin-right: 30px;
    	svg{
    		fill: $color-primary;
    		width: 70px;
    		height: 70px;
    	}
    }
    &__text{
    	b{
			color: $color-primary;
		    display: block;
    		font-size: 24px;
    		line-height: 26px;
    		margin-bottom: 12px;
    	}
    	span{
    		color: $color-text;
    	}
    	a{
    		color: $color-primary;
    		transition: $time;
    		&:hover{
    			text-decoration: underline;
    		}
    	}
    }
}

@media (max-width : 576px) {
	.notification-block{
		flex-direction: column;
	}
}