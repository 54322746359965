.footer-social-links-wrapper{
	display: flex;
	flex-wrap: wrap;
	padding: 10px 0 0;
}
.footer-social-link{
	$this: &;
	display: flex !important;
	align-items: center;
	text-decoration: underline;
	&:hover{
		#{$this}__icon{
			border-color: $color-general;
			> svg{
				fill: $color-general;
			}
		}
	}
	&__icon{
		margin: 0 10px 0 0;
	}
	&_wide{
		padding: 10px 0 0;
	}
}