.catalog-button {
    $this: &;
    position: relative;
    display: inline-flex;
    height: 100%;
    width: 155px;
    &-link {
        display: flex;
        align-items: center;
        height: 100%;
    }
    &-lines {
        display: inline-block;
		position: relative;
		height: 2px;
		width: 20px;
        background-color: $color-primary;
        margin-right: 12px;
        margin-top: -2px;
		&:after, &:before{
			content:"";
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $color-primary;
		}
		&:before{
			top: -6px;
		}
		&:after{
			bottom: -6px;
		}
    }

    &-title {
        display: inline-block;
        font-size: 17px;
        line-height: 19px;
        transition: $time;
        color: $color-text;
    }

    .catalog-menu {
        display: none;
    }

    &:hover & {
        &-link {
            
        }
    }

    &:hover {
        .catalog-button-title{
            color: $color-primary;
        }
        .catalog-menu {
            display: block;
        }
    }
}