.product-form{
	&__title{
		line-height: 38px;
		padding: 0 0 12px;
	}
	&__labels{
		display: flex;
		justify-content: space-between;
		padding: 0 0 12px;
		margin: 0 0 6px;
		border-bottom: 1px solid #e5e5e5;
	}
	&__vendor-code{
		color: #818a91;
		font-size: 16px;
	}
	&__labels + &__price-row{
		border-top: none;
	}
	&__price-row{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 17px 0 0;
    	padding: 26px 0;
		border-top: 1px solid #e5e5e5;
		border-bottom: 1px solid #e5e5e5;
		.wrapper{
			display: flex;
			align-items: center;
			.product-amount{
				width: 140px;
				height: 48px;
				margin: 0 20px 0 0;
				&__button{
					font-size: 10px;
				}
			}
		}
	}
	&__add-to-cart{
		position: relative;
		button{
			width: 110px;
			height: 48px;
		}
	}
	&__notify-button{
		button{
			width: 110px;
			height: 48px;
		}
	}
	&__button{
		display: flex;
		align-items: center;
		margin: 0 62px 0 0;
		border: none;
		background: transparent;
		color: #999;
		font-size: 15px;
		font-weight: 400;
		line-height: 24px;
		transition: .3s;
		cursor: pointer;
		> svg{
			margin: 0 12px 0 0;
			fill: #999;
			transition: .3s;
		}
		&:hover, &.active{
			> svg{
				fill: $color-general;
			}
			color: $color-general;
		}
	}
	&__buttons-wrapper{
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
	}
}


@media (max-width : 991px) {
	.product-form{
		&__title{
			padding: 12px 0;
		}
	}
}

@media(max-width: 575px){
	.product-form{
		&__title{
			line-height: 26px;
			padding: 5px 0 10px;
			font-size: 18px;
		}
		&__labels{
			flex-direction: column;
		}
		&__price-row{
			margin: 5px 0 0;
			padding: 15px 0;
			flex-direction: column;
			align-items: flex-start;
		}
	}
}