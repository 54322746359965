.catalog-card{
	display: flex;
	flex-direction: row;
	margin-bottom: 30px;
	&__thumb{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70px;
		flex: 0 0 70px;
		height: 70px;
		border: 1px solid #E5E5E5;
		border-radius: 5px;
		background-position: right;
		background-size: cover;
		margin-right: 30px;
		svg{
			width: 30px;
			height: 30px;
			fill: #EAEAEA;
		}
	}
	&__list{
		&-title{
			display: inline-block;
			font-weight: 500;
			font-size: 24px;
			line-height: 30px;
			color: #373A3C;
			margin-bottom: 15px;
			transition: $time;
			&:hover{
				color: $color-primary;
			}
		}
		ul{
			li{
				a{
					display: inline-block;
					padding: 5px 0;
					font-size: 16px;
					line-height: 18px;
					color: $color-text;
					transition: $time;
					&:hover{
						color: $color-primary;
					}
				}
			}
		}
	}
}
.big-opt{
	font-size: 14px;
    line-height: 16px;
    color: #999999;
}


@media(max-width: 575px){
	.catalog-card__thumb{
		width: 50px;
		height: 50px;
		flex: 0 0 50px;
		margin-right: 15px;
	}
	.catalog-card__list-title {
	    font-size: 20px;
	    line-height: 24px;
	    margin-bottom: 5px;
	}
}