.mobile-menu{
	&, & .sub-menu{
		position: fixed;
		z-index: 1005;
		left: 0;
		top: 75px;
		width: 100%;
		height: calc(100% - 60px);
		overflow-y: scroll;
		background-color: #fff;
		left: 110%;
		transition: .3s;
		&.opened{
			left: 0;
		}
		&.no-scroll{
			overflow: hidden;
		}
	}
	.main-menu{
		background-color: #f5f5f5;
	}
	.main-menu, .sub-menu{
		border-top: 1px solid #dee2e6;
		> li{
			> a{
				display: block;
				position: relative;
				padding: 17px 50px 17px 20px;
				color: #999;
				font-size: 15px;
				font-weight: 400;
				line-height: 16px;
				border-bottom: 1px solid #dee2e6;
				.open-child{
					&:after{
						border-left-color: #999;
					}
				}
			}
		}
	}
	
	.catalog-link{
		background-color: $color-primary;
		> a{
			display: flex;
			align-items: center;
			position: relative;
			height: 50px;
			color: #fff;
			font-size: 16px;
			line-height: 16px;
			> svg{
				margin: 0 15px 0 15px;
				fill: #fff;
			}
		}
	}

	&__footer{
		padding: 20px 20px;
		.header-contacts__price{
			margin-bottom: 10px;
			width: 220px !important;
		}
		.callback{
			    margin: 0 0 15px;
			    width: 220px;
			    height: 35px;
			    max-width: 100%;
			    background-color: $color-primary;
			    border-radius: 50px;
			    font-weight: 500;
			    font-size: 16px;
			    line-height: 16px;
			    color: #FFFFFF;
		}
		a.contact-row, span.contact-row{
			display: flex;
			padding: 6px 0;
			color: #373a3c;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			.icon{
				width: 24px;
			    flex: 0 0 24px;
				> svg{
					fill: $color-primary;
				}
			}
		}
	}
	.open-child{
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 50px;
		height: 100%;
		&:after{
			content: "";
			position: absolute;
			border: 4px solid transparent;
			border-left: 5px solid #fff;
			right: 10px;
			top: 22px;
		}
	}
	.step_back{
		background-color: #f5f5f5;
		> svg{
			fill: #999;
			margin: 0 8px 2px 0;
		}
	}
}

@media (max-width: 479px){
	.mobile-menu,
	.mobile-menu .sub-menu{
		top: 68px;
	}
}