.mini-button {
    $this: &;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border: 1px solid $color-general;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 100ms ease, transform 75ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;

    &__icon {
        width: 16px;
        height: 14px;
        fill: $color-general;
        transition: fill 100ms ease;
    }

    &:hover, &.active {
        background-color: $color-general;

        #{$this}__icon {
            fill: #fff;
        }
    }

    &:active {
        transform: scale(1.2);
    }
}