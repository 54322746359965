.product-card {
    outline: none;
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 290px;
    margin: 0 auto 30px;
    &__thumb{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 220px;
        border-bottom: 1px solid #E5E5E5;
        padding: 15px;
        img{
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
        svg{
            width: 30px;
            height: 30px;
            fill: #eaeaea;
        }
        &-badges{
            position: absolute;
            top: 7px;
            left: 15px;
            .product-badges{
                margin-right: 5px;
                padding: 5px;
                border-radius: 3px;
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
                text-align: center;;
                text-transform: uppercase;
                color: #FFFFFF;
                &-sale{
                    padding: 3px 7px 4px;
                    font-size: 12px;
                    background-color: #86BA4B;
                }
                &-hit{
                    background-color: #364AB8;
                }
                &-new{
                    background-color: #C33C3C;
                }
            }
        }
    }
    &__info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 180px;
        padding: 20px;
        &-title{
            font-size: 16px;
            line-height: 22px;
            color: $color-text;
        }
        &-price{
            font-size: 14px;
            line-height: 22px;
            color: black;
            b{
                font-weight: 500;
                font-size: 18px;
                color: $color-text;
            }
            button{
                display: block;
                margin: 10px auto 0;
                width: 100%;
                height: 40px;
                border: none;
                outline: none;
                background-color: $color-primary;
                border-radius: 50px;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                transition: $time;
                cursor: pointer;
            }
            &.old_price{
                b{
                    color: #D95652;
                }
            }
        }
    }

    &:hover{
        button{
            background-color: #75AA39;
        }
    }

    &.removed {
        &::after {
            content: "";
            position: absolute;
            z-index: 6;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }

    &.removed & {
        &-remove {
            display: none;
        }

        &-restore {
            display: block;
        }
    }

    .loading-blocks &:after {
        content: "";
        position: absolute;
        z-index: 99;
        top: -1px;
        left: -1px;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
        background-image: url("../images/card_big.jpg");
        background-size: 100% 100%;
    }
}

@media (max-width : 576px) {
    .product-card{
        margin-bottom: 15px;
    }
}