.products-container {
	&.loading{
		position: relative;
		&:after{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255,255,255,0.6);
		}
	}
	.product-card-wide:first-child{
		margin-top: 15px;
	}
}