body{
	&.loading-blocks{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		overflow: hidden;
		.mobile-filters-trigger{
			position: relative;
			&:after{
				content: "";
				position: absolute;
				z-index: 99;
				top: -1px;
				left: -1px;
				height: calc(100% + 2px);
				width: calc(100% + 2px);
				background-color: #d1d1d1;
				// background-image: url('../images/sort.svg');
				// background-size: cover;
				// background-position: center top;
				
			}
		}
		.sort-parameters__order-by{
			position: relative;
			overflow: hidden;
			&:after{
				content: "";
				position: absolute;
				z-index: 99;
				top: -1px;
				left: -1px;
				height: calc(100% + 2px);
				width: calc(100% + 2px);
				background-image: url('../images/sort.svg');
				background-size: cover;
				background-position: center top;
				
			}
		}
		.sort-parameters__layout-types button{
			position: relative;
			&:after{
				content: "";
				position: absolute;
				z-index: 99;
				top: -2px;
				left: -2px;
				height: calc(100% + 4px);
				width: calc(100% + 4px);
				background-image: url('../images/square.svg');
				background-size: cover;
				background-position: center top;
			}
		}
		.catalog-categories{
			position: relative;
			overflow: hidden;
			height: 70px;
			&:after{
				content: "";
				position: absolute;
				z-index: 99;
				top: -1px;
				left: -1px;
				height: calc(100% + 2px);
				width: calc(100% + 2px);
				background-image: url('../images/subcategory.svg');
				background-size: cover;
				background-position: center top;
			}
		}
		.product-card{
			&:after{
				content: "";
				position: absolute;
				z-index: 99;
				top: -1px;
				left: -1px;
				height: calc(100% + 2px);
				width: calc(100% + 2px);
				background-image: url('../images/card_big.jpg');
				background-size: 100% 100%;
			}
		}
		.product-card-wide{
			position: relative;
			height: 70px;
			overflow: hidden;
			&:after{
				content: "";
				position: absolute;
				z-index: 99;
				top: -1px;
				left: -1px;
				height: calc(100% + 2px);
				width: calc(100% + 2px);
				background-image: url('../images/card_horizontal.jpg');
				background-size: 100% 100%;
			}
		}
		.filters{
			position: relative;
			height: 1020px;
			overflow: hidden;
			&:after{
				content: "";
				position: absolute;
				z-index: 99;
				top: -1px;
				left: -1px;
				height: calc(100% + 2px);
				width: calc(100% + 2px);
				background-image: url('../images/filter.svg');
				background-size: 100% auto;
				background-position: center top;
			}
		}
	}
}

@media (max-width: 1199px) {
	body{
		&.loading-blocks{
			.filters{
				position: fixed;
			}
		}
	}
}

@media(max-width: 575px){
	body{
		&.loading-blocks{
			.wide-product-card{
				height: 250px;
				&:after{
					background-image: url('/static/images/mobile_wide_card.jpg');
				}
			}
		}
	}
}