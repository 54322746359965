.cart-button {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    &__icon {
        fill: $color-primary;
        margin-right: 10px;
        width: 35px;
        height: 25px;
    }

    &__info{
        display: block;
        &-title{
            display: block;
            font-size: 14px;
            line-height: 17px;
            color: $color-text;
            margin-bottom: 3px;
            font-weight: 500;
            transition: $time;
            b{
                position: relative;
                &:not(:empty){
                    &::before{
                        transition: $time;
                        content: ':';
                        position: relative;
                        display: inline-block;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: $color-text;
                        margin-right: 3px;
                    }
                }
            }
        }
        &-total{
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: $color-primary;
            i{
                font-style: normal;
                &:not(:empty){
                    &::after{
                        content: 'руб.';
                        position: relative;
                        display: inline-block;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: $color-primary;
                        margin-left: 3px;
                    }
                }
            }
        }
        &.filled{
            .cart-button__info-title{
                font-weight: 400;
            }
        }
    }

    &:hover {
        .cart-button__info-title{
            color: $color-primary;
        }
        .cart-button__info-title b:not(:empty)::before{
            color: $color-primary;
        }
    }

    &__mobile{
        display: block;
        position: relative;
        margin: 15px;
        svg{
            width: 30px;
            height: 25px;
            fill: $color-primary;
        }
        span{
            background-color: #fff;
            display: block;
            position: absolute;
            right: -8px;
            top: -5px;
            border-radius: 5px;
            padding: 0 5px;
            font-size: 12px;
            color: #86ba4b;
        }
    }
}

.soc{
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
}
.mobile-menu__footer .soc{
    margin-top: 20px;
    margin-left: 0;
    margin-bottom: 15px;
}