.filters{
	&__form{
		border: 1px solid #e5e5e5;
		background-color: #fff;
	    border-radius: 5px;
    	overflow: hidden;
    	margin-bottom: 30px;
	}
	
	&__footer{
		padding: 16px 12px;
		&-result{
			font-size: 16px;
			line-height: 30px;
			color: $color-text;
			text-align: center;
			padding-bottom: 15px;
		}
		&-buttons{
			display: flex;
			justify-content: space-between;
			input{
				border-radius: 50px;
				width: 115px;
				height: 30px;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				transition: $time;
			}
			input[type="submit"]{
				border: 1px solid $color-primary;
				background-color: $color-primary;
				color: #FFFFFF;
				&:hover{
					background-color: #75AA39;
				}
			}
			input[type="reset"]{
				background-color: transparent;
				border: 1px solid #E5E5E5;
				color: $color-text;
				&:hover{
					color: $color-primary;
					border-color: $color-primary;
				}
			}
		}
	}

	&__mobile-footer{
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100px;
		padding: 16px 12px;
		border-top: 1px solid #e5e5e5;
	}
	&__mobile-result{
		color: #373a3c;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		padding-bottom: 15px;
	}
	&__mobile-controllers{
		display: flex;
		justify-content: space-between;
		button{
			border-radius: 50px;
			width: 115px;
			height: 30px;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			transition: $time;
		}
		button.filters__mobile-submit{
			border: 1px solid $color-primary;
			background-color: $color-primary;
			color: #FFFFFF;
			&:hover{
				background-color: #75AA39;
			}
		}
		button.filters__mobile-reset{
			background-color: transparent;
			border: 1px solid #E5E5E5;
			color: $color-text;
			&:hover{
				color: $color-primary;
				border-color: $color-primary;
			}
		}
	}
}



@media (max-width: 1199px) {
	.filters{
		background-color: #fff;
		position: fixed;
		z-index: 800;
		width: 270px;
		height: 100%;
		right: 0;
		top: 0;
		overflow-y: auto;
		transform: translateX(110%);
		transition: .3s;
		&.opened{
			transform: translateX(0%);
		}
		&__footer{
			display: none;
		}
		&__mobile-wrapper{
			height: calc(100% - 100px);
			overflow-y: auto;
		}
	}
}