.header-logo {
    display: flex;
    flex-direction: row;
    img{
		width: 45px;
		height: 45px;
		margin-right: 3px;
		margin-right: 13px;
    }
    span{
    	b{
			display: block;
			font-family: "Times New Roman" !important;
			font-size: 30px;
			line-height: 34px;
			color: #1C4526;
			font-weight: normal;
			margin-bottom: -10px;
    	}
    	i{
    		display: inline-block;
		    text-align: center;
		    width: 100%;
			font-style: normal;
		    font-size: 13px;
    		letter-spacing: 0.3px;
			line-height: 15px;
			color: #1C4526;
    	}
    }
}