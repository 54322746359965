.product{
	padding-top: 0 !important;
	&-top{
		margin-bottom: 30px;
	}
	&-empty{
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		b{
			font-weight: 500;
			font-size: 24px;
			line-height: 40px;
			color: $color-text;
		}
		a{
			font-weight: 700;
			font-size: 16px;
			line-height: 21px;
			color: #FFFFFF;
			transition: $time;
			background-color: $color-primary;
			height: 40px;
			padding: 0 15px;
			&:hover{
				background-color: #75AA39;
			}
		}
	}
	&-title{
		padding-bottom: 20px;
		border-bottom: 1px solid #E6E6E6;
		h1{
			font-weight: 500;
			font-size: 30px;
			line-height: 40px;
			color: $color-text;
		}
	}
	&-info{
		padding: 18px 0;
		em{
			display: inline-block;
			border: 1px solid $color-primary;
			border-radius: 5px;
			margin-right: 10px;
			font-style: normal;
			padding: 0 10px;
			color: $color-primary;
		}
		span{
			font-size: 16px;
			line-height: 19px;
			color: $color-text;
		}
	}
	&-color-picker{
		border: 1px solid #CFCFCF;
		border-radius: 5px;
		padding: 5px;
		position: absolute;
    	background: #fff;
		span{
			display: block;
			position: relative;
			border: 1px solid #CFCFCF;
			width: 45px;
			height: 30px;
			border-radius: 5px;
			
		}
		&__dropdown{
			display: none;
			flex-wrap: wrap;
			padding-top: 5px;
			margin-left: -2px;
			input{
				display: none;
			}
			label{
				cursor: pointer;
			    width: 18px;
			    height: 14px;
			    border-radius: 3px;
			    margin: 2px;
			    border: 1px solid #eaeaea;
			}
		}

		&:not(.empty){
			width: 90px;
			cursor: pointer;
			span{
				&::after{
					content: '';
					display: block;
					position: absolute;
					width: 8px;
					height: 8px;
					border-top: 1px solid #CFCFCF;
					border-right: 1px solid #CFCFCF;
					transform: rotate(135deg);
					transition: $time;
				    right: -22px;
	    			top: 9px;
				}
			}
			&.active{
				border-color: $color-primary;
				z-index: 9;
				span::after{
					border-color: $color-primary;
					transform: rotate(-45deg);
					top: 13px;
				}
				.product-color-picker__dropdown{
					display: flex;
				}
			}
		}

		
	}
	&-notify{
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 5px;
		margin-bottom: 15px;
		padding: 20px 10px 0;
		p{
		    margin-bottom: 20px;
    		line-height: 20px;
		}
	}
}


@media (max-width: 767px) {
	.product-top {
	    margin-bottom: 15px;
	}
	.product-conditions div.delivery{
		border-right: none !important;
		border-bottom: 1px solid #E6E6E6;
		margin-bottom: 14px;
	}
	.product-conditions{
		padding: 15px 20px;
		flex-direction: column !important;
	}
	.product-conditions div.wallet{
		padding: 0 !important;
	}
	.product-color-picker{
		width: 75px;
	}
	.product-color-picker span{
		width: 35px;
		height: 25px;
	}
	.product-color-picker span::after{
		top: 5px;
	}
}

@media (max-width: 439px) {
	.product-title h1{
	    font-size: 26px;
    	line-height: 32px;
	}
	.product-info em{
	    padding: 0 8px;
	    margin-right: 3px;
	}
	.product-info span{
		font-size: 14px;
	}
}
