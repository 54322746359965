.header-contacts {
    display: flex;
    flex-direction: row;
    padding-top: 4px;
    &__price{
        width: 225px;
        height: 35px;
        svg{
            width: 12px;
            height: 12px;
            fill: $color-primary;
            margin-right: 4px;
            margin-top: -2px;
            transition: $time;
        }
        span{
            color: $color-primary;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            transition: $time;
        }
        &:hover{
            background-color: $color-primary;
            svg{
                fill: #fff;
            }
            span{
                color: #fff;
            }
        }
    }
    &__call{
        width: 135px;
        height: 35px;
        background-color: $color-primary;
        margin-right: 20px;
        margin-left: 18px;
        transition: $time;
        span{
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #fff;
        }
        &:hover{
            background-color: #75AA39;
        }
    }
    &__phone{
        svg{
            margin-right: 1px;
            margin-top: -6px;
            fill: $color-text;
            width: 14px;
            height: 14px;
        }
        span{
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            color: #333333;
        }
    }
}
