.contacts-block {
	padding: 0 0 30px;
	&__title {
		display: block;
		padding: 0 0 16px;
		color: #373a3c;
		font-size: 18px;
		font-weight: 600;
	}
	&__info {
		padding: 20px 10px 0;
	}
	&__map-container {
		height: 400px;
		margin-right: -15px;
	}
}
.contacts-content{
	background-color: #fff;
	margin-top: 15px;
	padding: 25px;
	.content-text img {
	    height: auto !important;
	    object-fit: contain !important;
	    margin: 0 15px 15px !important;
	    width: 30% !important;
	    height: auto !important;
	}
}

button.row-contacts-choose {
    color: #fff;
    background-color: #86BA4B;
    border: 1px solid #86BA4B;
    border-bottom-width: 0;
	padding: 10px 15px;
	outline-width: 0;
	&.active {
		background-color: #fff;
		color: #86BA4B;
	}
}

@media (max-width: 991px) {
	.contacts-block__map-container{
		margin: 10px;
	}
}
@media (max-width: 767px) {
	.contacts-block {
		padding: 0 0 20px;
		&__map-container {
			height: 230px;
		}
	}
}