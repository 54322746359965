.sidebar-helper{
	display: block;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 320px;
	max-width: 270px;
	border-radius: 5px;
	overflow: hidden;
	&:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-primary;
		opacity: 0.6;
	}
	&__wrapper{
		position: absolute;
		z-index: 2;
		max-width: calc(100% - 32px);
		bottom: 18px;
		left: 16px;
	}
	&__title{
		display: block;
		font-weight: 500;
		font-size: 24px;
		line-height: 30px;
		color: #FFFFFF;
	}
	&__button{
		margin: 20px 0 0;
		border: 1px solid #fff;
		border-radius: 50px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #FFFFFF;
		transition: .3s;
		width: 135px;
		height: 40px;
		&:hover{
			background-color: #fff;
			color: $color-primary;
		}
	}
}