.mobile-search-trigger{
	margin: 15px;
	svg{
		width: 20px;
		height: 20px;
		fill: $color-primary;
	}
}
.mobile-search-wrapper{
	position: fixed;
	z-index: 1011;
	left: 0;
	top: 75px;
	width: 100%;
	height: calc(100% - 60px);
	overflow-y: scroll;
	background-color: #fff;
	transform: translateX(100%);
	transition: .3s;
	&.opened{
		transform: translateX(0%);
	}
	&.child_opened{
		overflow: hidden;
	}
	.search-form{
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 10px 15px;
		height: 60px;
		background-color: $color-primary;
		input{
			width: calc(100% - 85px);
			padding: 0 15px;
			border-radius: 4px;
		}
		button{
			display: flex;
			align-items: center;
			justify-content: center;
			position: static;
		    font-size: 14px;
    		line-height: 16px;
    		color: #999999;
			width: 80px;
			height: 100%;
			border-radius: 4px;
			border: none !important;
			outline: none !important;
			background-color: #fff;
		}
	}
	.ajax_search{
		ul{
			li{
				a{
					display: block;
					padding: 5px 20px;
					color: #373a3c;
					font-size: 15px;
					font-weight: 400;
					line-height: 20px;
					transition: .3s;
					&:hover{
						color: $color-primary;
					}
				}
			}
		}
		&>ul{
			&>li{
				&>a{
					background-color: $color-primary;
					color: #fff;
				}
			}
		}
	}
}

@media (max-width: 479px){
	.mobile-search-wrapper{
		top: 68px;
	}
}