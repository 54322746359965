.row.row-contacts{
	margin: 0;
	background-color: #fff;
	border-radius: 5px;
	overflow: hidden;
}
.requisite-block {
	margin: 0 0 13px;
	&__title {
		display: block;
		color: $color-primary;
		font-size: 14px;
		font-weight: 600;
	}
	&__value {
		display: block;
		color: $color-text;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}

	a{
		transition: $time;
		&:hover{
			color: $color-primary;
		}
	}
}