* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    min-height: 100%;
    height: 100%;
}

body {
    min-width: 320px;
    height: 100%;
    margin: 0;
    padding: 0;
	font-family: Roboto !important;
	&.opened {
		// position: fixed;
		// left: 0;
		// right: 0;
		// top: 0;
		overflow: hidden;
	}
}

::-webkit-input-placeholder { color: $color-placeholder; }
::-moz-placeholder          { color: $color-placeholder; }
:-moz-placeholder           { color: $color-placeholder; }
:-ms-input-placeholder      { color: $color-placeholder; }

ol, ul {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4, p {
    line-height: normal;
    margin: 0;
    padding: 0;
}
a:hover{
    text-decoration: none;
}
// Базовая обертка сайта
.base {
    &-wrapper, &-container {
        width: 100%;
    }
    
    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &-container {
        flex: 1  auto;
        overflow: initial;
    }

    &-page {
        padding-bottom: 90px;
    }
}


.mobile-search-trigger{
	svg{
		pointer-events: none;
	}
}
.nav-close{
	*{
		pointer-events: none;
	}
}
.simple-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid $color-primary;
}

@media (min-width: 1199px){
    .container{
        max-width: 1200px;
    }
}

