.breadcrumbs {
	padding: 10px 0 8px;
	ul {
		display: block;
		li {
			display: inline;
			a, span{
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				color: #fff;
			}
			a:hover{
				text-decoration: underline;
			}
			&:not(:last-child){
				&:after{
					content: '/';
					margin: 0 0 0 4px;
					color: #fff;
					font-size: 16px;
					font-weight: 400;
				}
			}
		}
	}
}

@media(max-width: 576px) {
	.breadcrumbs {
	    padding: 10px 0;
	}
	.breadcrumbs ul li a,
	.breadcrumbs ul li span{
		font-size: 14px;
	}
}