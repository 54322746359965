@-webkit-keyframes loading {
    0 {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    12.5% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    25% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    37.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    62.5% {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
    }

    75% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    87.5% {
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes shake {

    10%,
    90% {
        -webkit-transform: translate3d(-1px, 0, 0);
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        -webkit-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        -webkit-transform: translate3d(-4px, 0, 0);
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        -webkit-transform: translate3d(4px, 0, 0);
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes shake {

    10%,
    90% {
        -webkit-transform: translate3d(-1px, 0, 0);
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        -webkit-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        -webkit-transform: translate3d(-4px, 0, 0);
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        -webkit-transform: translate3d(4px, 0, 0);
        transform: translate3d(4px, 0, 0);
    }
}
