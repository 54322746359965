.load_content{
	width: 100%;
	height: 50px;
	background-color: #FFFFFF;
	border: 1px solid #E5E5E5;
	border-radius: 5px;
	font-size: 16px;
	line-height: 22px;
	color: $color-text;
	margin-bottom: 30px;
	outline: none !important;
	transition: $time;
	cursor: pointer;
	&:hover{
		background-color: $color-primary;
		border-color: $color-primary;
		color: #fff;
	}
}
.pagination{
	.page-item{
		&.active{
			.page-link{
				background-color: $color-primary;
				border-color: $color-primary;
				color: #fff !important;
			}
		}
		.page-link{
			box-shadow: none !important;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: -0.04em;
			color: $color-text !important;
			padding: 8px 15px !important;
		}
		&:not(.active) a.page-link:hover{
			background-color: #fff !important;
			border-color: $color-primary !important;
			color: $color-primary !important;
		}
		&:not(.active) span.page-link:hover{
			background-color: #fff !important;
		}
	}
}
@media (max-width: 992px) {
  
}