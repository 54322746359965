.product-card-wide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 0;
    border-top: 1px solid #E5E5E5;
    &:last-child{
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 30px;
    }
    &-body,
    &-actions{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
    }
    &-actions{
        justify-content: space-between;
    }
    &-thumb{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        width: 55px;
        height: 55px;
        flex: 0 0 55px;
        margin-right: 10px;
        img{
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
        svg{
            fill: #E5E5E5;
            width: 25px;
            height: 25px;
        }
    }
    &-title{
        font-size: 16px;
        line-height: 19px;
        color: $color-text;
    }
    &-price{
        white-space: nowrap;
        text-align: left;
        color: black;
        :hover{
            color:unset;
        }
        b{
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            color: $color-text;
        }
        strike{
            display: inline-block;
            font-size: 14px;
            line-height: 16px;
            color: #999999;
        }
        &.old_price{
            b{
                color: #D95652;
            }
        }
    }
    &-badges{
        span{
            border-radius: 3px;
            padding: 3px 5px;
            line-height: 12px;
            letter-spacing: 0.03em;
            color: #FFFFFF;
            font-size: 12px;
            text-transform: uppercase;
            &.sale{
                background: #86BA4B;
            }
            &.hit{
                background: #364AB8;
            }
            &.new{
                background: #C33C3C;
            }
        }
        
    }
    &-button{
        button{
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            color: #FFFFFF;
            background-color: $color-primary;
            border-radius: 50px;
            transition: $time;
            outline: none;
            border: none;
            padding: 10px 14px;
            cursor: pointer;
            &:hover{
                background-color: #75AA39;
            }
        }
    }
}
.big-opt{
    color:#999999 !important;
}
@media (max-width: 992px) {
    .product-card-wide{
        flex-direction: column;
    }
    .product-card-wide-body{
        margin-bottom: 15px;
    }
}

@media (max-width: 419px) {
    .product-card-wide-actions{
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }
    .product-card-wide-price{
        margin-bottom: 6px;
    }
    .product-card-wide-button{
        bottom: 0;
        right: 0;
        position: absolute;
    }
    .load_content{
        margin-bottom: 14px !important;
        height: 35px !important;
    }
    .pagination .page-item .page-link{
        padding: 5px 12px !important;
    }
}