.header-menu{
	flex: 1 0;
    padding-right: 200px;
	ul{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		height: 100%;
		li{
			position: relative;
			&>a, &>span{
				display: block;
				font-size: 17px;
				line-height: 19px;
		    	padding: 12px 15px;
				color: $color-text;
				transition: $time;
				cursor: pointer;
			}
			&.has_children{
				padding-right: 10px;
				&::after{
					content: '';
					display: block;
					position: absolute;
					width: 8px;
					height: 8px;
					border-top: 1px solid;
					border-right: 1px solid;
					border-color: #333333;
					transform: rotate(135deg);
				    right: 8px;
    				top: calc(50% - 6px);
    				transition: $time;
				}
			}
			&:hover{
				&>a, &>span{
					color: $color-primary;
				}
				&::after{
					border-color: $color-primary;
				}
				.header-menu__dropdown{
					display: flex;
				}
			}
		}
	}
	&__dropdown{
		display: none;
	    position: absolute;
	    width: 220px;
	    flex-direction: column;
	    background: #fff;
	    top: calc(100% + 2px);
	    padding: 10px 15px;
	    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
	    &>a{
	    	font-size: 17px;
			line-height: 19px;
			color: $color-text;
			padding: 5px 0;
			transition: $time;
			&:hover{
				color: $color-primary;
			}
	    }
	    &::before{
	    	content: '';
	    	display: block;
	    	width: 100%;
	    	height: 20px;
	    	position: absolute;
	    	top: -10px;
	    	left: 0;
	    	background-color: transparent;
	    }
	}
}
