// Текст
.content-text {
	ol ol, ol ul, ul ol, ul ul {
		padding-top: 8px;
	}
	h1 {
		padding: 0 0 12px;
		@include font-h1;
	}
	h2 {
		padding: 15px 0 12px;
		@include font-h2;
	}
	h3 {
		padding: 14px 0 5px;
		@include font-h3;
	}
	p {
		padding: 0 0 16px;
		@include font-p;
	}
	a {
		color: $color-general;
		text-decoration: underline;
		transition: .3s;
		&:hover, &focus {
			color: #0056b3 !important;
		}
		&:visited{
			color: #7a339e !important;
		}
	}
	ol + h3, ul + h3{
		padding-top: 16px;
	}
	ol {
		padding: 4px 0 0;
		margin-left: 28px;
		> li {
			@include font-p;
			list-style-type: decimal;
			padding-bottom: 8px;
			padding-left: 8px;
		}
		ul{
			li:last-child{
				padding-bottom: 0;
			}
		}
	}
	ul {
		margin-left: 35px;
		padding: 0 0 30px;
		> li {
			position: relative;
			@include font-p;
			padding-bottom: 8px;
			&:before {
				content: '–';
				position: absolute;
				left: -20px;
			}
		}
	}
	img {
		width: 100%;
		height: auto;
		margin: 14px 0 17px;
		-o-object-fit: cover;
		object-fit: cover;
		&.noresize{
			height: auto !important;
		}
	}
	table {
		th, td {
			color: #373a3c;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			padding: 8px;
			border-right: 1px solid $color-border;
			border-bottom: 1px solid $color-border;
			&:last-child {
				border-right: 0;
			}
		}
		thead {
			tr {
				th {
					color: #999;
					background-color: #f5f5f5;
				}
			}
		}
		tbody {
			tr {
				p{
					line-height: 20px;
				}
				// &:first-child td{
				// 	color: #999;
				// 	background-color: #f5f5f5;
				// }
				&:last-child td {
					border-bottom: 0;
				}
			}
		}
	}
}

@media(max-width: 767px) {
	@import "../media-mixins";

	.content-text {
		ol, ul {
			padding-top: 5px;
		}

		h1 {
			@include font-h1;
			padding: 5px 0 10px;
		}
		h2 {
			@include font-h2;
			padding: 5px 0 0;
			line-height: 26px;
		}
		h3 {
			@include font-h3;
			padding: 5px 0 0;
			line-height: 20px;
		}
		p {
			@include font-p;
			padding: 5px 0 5px;
		}
		ol {
			padding: 5px 0;
			margin-left: 15px;
			> li {
				@include font-p;
				padding-bottom: 5px;
			}
		}
		ul {
			margin-left: 15px;
			> li {
				@include font-p;
				padding-bottom: 5px;
				&:before {
					left: -20px;
				}
			}
		}
		img {
			margin: 5px 0;
		}
		table {
			th, td {
				font-size: 14px;
				line-height: 20px;
				padding: 8px 10px;
			}
		}
	}
}