.footer-social-icon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 4px;
	border: 1px solid #ccc;
	transition: .2s;
	> svg{
		fill: #999;
		transition: .2s;
	}
}