.subscribe-desc {
    max-width: 200px;
    padding: 0 10px;

    &-icon {
        width: 62px;
        height: 55px;
    }

    &-title {
        line-height: 19px;
        padding-top: 12px;
    }
}