.catalog-categories {
	border-radius: 5px;
	margin: 0 0 12px;
	$this: &;
	&__link, &__open, &__close{
		display: inline-block;
		text-align: center;
		transition: .2s;
		border: 1px solid #E5E5E5;
		border-radius: 50px;
		font-size: 16px;
		line-height: 22px;
		color: $color-text;
		transition: $time;
		padding: 3px 14px;
		background-color: #fff;
		margin-bottom: 3px;
		&:hover{
			border: 1px solid $color-primary;
			color: $color-primary;
			#{$this}__amount{
				color: $color-primary;
			}
		}
		&.active{
			color: #fff;
			background: $color-primary;
			border: 1px solid $color-primary;
			#{$this}__amount{
				color: #fff;
			}
		}
		
		#{$this}__close{
			padding: 5px 10px;
			svg{
				transform: rotate(180deg);
			}
		}
	}
	&__open, &__close{
		min-width: 60px;
		border-radius: 4px;
		border: 1px solid #adadad;
		background-color: #e6e6e6;
		&:hover{
			border: 1px solid $color-primary;
			span{
				color: $color-primary;
			}
			svg{
				fill: $color-primary;
			}
		}
		span{
			display: inline-block;
			vertical-align: middle;
			color: $color-text;
			font-size: 16px;
			line-height: 22px;
		}
		svg{
			display: inline-block;
			vertical-align: middle;
			fill: $color-text;
			margin: 2px 0 0 5px;
		}
	}
	&__amount {
		color: $color-text;
		transition: .2s;
	}
	&__hidden-links{
		display: none;
	}
}