.brand-block{
	display: flex;
	margin: 0 0 40px;
	&__letter{
		width: 43px;
		min-width: 43px;
		color: $color-general;
		font-size: 32px;
		font-weight: 900;
		text-transform: uppercase;
		letter-spacing: 1.8px;
	}
	&__list{
		padding: 2px 0 0;
		li{
			a{
				display: block;
				padding: 6px 0 7px;
				color: #373a3c;
				font-size: 14px;
				font-weight: 400;
				line-height: 18px;
				transition: .3s;
				&:hover{
					color: $color-general;
				}
			}
		}
	}
}

@media (max-width : 767px) {
	.brand-block{
		margin: 0 0 10px;
		&__list{
			li{
				a{
					padding: 4px 0;
				}
			}
		}
	}
}