.certificates {
	padding: 12px 0 16px;
}



@media(max-width: 767px) {
	.certificates {
		padding: 5px 0 10px;
	}
}

@media (min-width: 768px) {
  .content .certificates div {
    margin-bottom: 15px;
  }
}
