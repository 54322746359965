.product-conditions{
	display: flex;
	flex-direction: row;
	background: #FFFFFF;
	border: 1px solid #E5E5E5;
	border-radius: 5px;
	padding: 30px 30px 20px;
	margin-bottom: 30px;
	div{
		display: flex;
		flex-direction: row;
		flex: 1;
		&.delivery{
			border-right: 1px solid #E6E6E6;
		}
		&.wallet{
			padding-left: 35px;
		}
	}
	svg{
		width: 38px;
		height: 28px;
		flex: 0 0 38px;
		fill: $color-primary;
		margin-right: 12px;
	}
	p{
		span{
			font-size: 15px;
			line-height: 20px;
			color: #787878;
			display: block;
			margin-bottom: 10px;
		}
	}
}

@media (max-width : 767px) {
	.product-conditions{
		margin-bottom: 15px;
	}
}

@media (max-width : 439px) {
	.product-conditions{
		padding: 15px;
	}
}