.product-amount {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 85px;
    height: 40px;
    // border: 1px solid #ccc;
    background-color: #fff;
    // border-radius: 5px;
    overflow: hidden;

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 100%;
        font-size: 14px;
        line-height: 17px;
        color: #787878;
        font-weight: 700;
        transition: .2s;
        cursor: pointer;
        user-select: none;
        background-color: #E6E6E6;
        border: 1px solid #CFCFCF;
        
        &:hover {
            color: $color-primary;
            border-color: $color-primary; 
            background-color: #fff;
        }
        &_minus{
            border-radius: 5px 0 0 5px;
        }
        &_plus{
            border-radius: 0 5px 5px 0;
        }
    }

    &__field {
        display: flex;
        width: 80px;
        border-top: 1px solid #CFCFCF;
        border-bottom: 1px solid #CFCFCF;

        input {
            border: none;
            box-shadow: none;
            outline: none;
            width: 100%;
            text-align: center;
            color: #373a3c;
            font-size: 15px;
            line-height: 21px;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}
