.notify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 35px 40px;
    margin-bottom: 30px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;

    &-body {
        display: flex;
        align-items: center;
    }

    &-text {
        // Стили текстовой обертки
    }

    &-icon {
        flex: 0 0 auto;
        width: 59px;
        height: 52px;
        fill: $color-primary;
        margin-right: 30px;
    }

    &-title {
        font-size: 24px;
        font-weight: 700;
        color: $color-primary;
        line-height: 1.5;
    }

    &-desc {
        font-size: 16px;
        font-weight: 400;
        color: $color-caption;
        line-height: 1.5;
    }

    &-button {
        border-radius: 50px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        padding: 10px 20px;
        transition: $time;
        background-color: $color-primary;
        &:hover{
            background-color: #75AA39;
            color: #fff;
        }
    }
}

@media(max-width: 767px) {
    .notify {
        flex-direction: column;
        align-items: flex-start;

        &-body {
            margin-bottom: 15px;
        }

        &-icon {
            margin-right: 15px;
        }
    }
}

@media(max-width: 575px) {
    .notify {
        padding: 15px 20px;

        &-body {
            flex-direction: column;
            align-items: flex-start;
        }

        &-icon {
            margin-right: 15px;
        }
    }
}
