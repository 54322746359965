.filter-block{
	border-bottom: 1px solid #e5e5e5;
	&__header{
		display: block;
		position: relative;
		background-color: #f5f5f5;
		padding: 7px 12px;
		border-bottom: 1px solid #e5e5e5;
		font-weight: 500;
		font-size: 16px;
		line-height: 30px;
		color: $color-text;
		transition: .3s;
		&:hover{
			color: $color-primary;
		}
		&.collapsed{
			border-bottom-color:transparent;
			&:after{
				transform: rotate(0deg);
			}
		}
		&:after{
			content: "";
			position: absolute;
			right: 15px;
			top: 22px;
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: .255em;
			vertical-align: .255em;
			border-top: 4px solid;
			border-right: 4px solid transparent;
			border-bottom: 0;
			border-left: 4px solid transparent;
			transform: rotate(180deg);
			transition: transform .3s linear;
		}
	}
	&__body{
		.custom-control{
			padding: 3px 0 3px 24px;
			&-label{
				&:before, &:after{
					top: 3px;
				}
			}
			input{
				display: none;
			}
		}
	}
	&__wrapper{
		padding: 13px;
		overflow: hidden;
	}
	&__scroll-content{
		max-height: 210px;
		.os-content{
			padding: 0 15px 0 0 !important;
		}
	}
	&__search_field{
		margin-bottom: 14px;
	}
}