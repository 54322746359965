.index-section {
    position: relative;
    &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 30px;
        p{
            font-weight: 500;
            font-size: 36px;
            line-height: 50px;
            color: $color-text;
            img{
                width: 25px;
                height: 25px;
                margin-top: -8px;
                margin-right: 15px;
            }
        }
        a{
            display: block;
            font-size: 14px;
            line-height: 16px;
            color: #999999;
            transition: $time;
            text-decoration: none;
            svg{
                margin-left: 5px;
                width: 15px;
                height: 10px;
                fill: $color-primary;
            }
            &:hover{
                color: $color-primary;
                text-decoration: underline;
            }
        }
    }
    &__mobile-more{
        display: none;
        max-width: 290px;
        margin: 0 auto;
        a{
            height: 40px;
            background-color: $color-primary;
            font-size: 16px;
            color: #fff;
        }
    }
    &-advantages{
        padding: 30px 0;
        background-color: $color-primary;
        .advantages-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            &__block{
                max-width: 190px;
                text-align: center;
                svg{
                    width: 55px;
                    height: 55px;
                    fill: #fff;
                    margin-bottom: 20px;
                }
                p{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    color: #FFFFFF;
                }
            }
        }
    }
    &-category{
        background-color: $color-bg;
        padding: 70px 0 20px;
    }
    &-products{
        background-color: $color-bg;
        padding-bottom: 30px;
    }
   &-partners{
        padding: 50px 0 48px;
        .partners{
            &-bg{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                div{
                    height: 100%;
                    width: 49.7vw;
                    background-color: #86BA4B;
                }
            }
            &-content{
                p{
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 42px;
                    text-align: center;
                    margin-bottom: 40px;
                    position: relative;
                    z-index: 9;
                    svg{
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        right: 90px;
                        top: -30px;
                        z-index: -1;
                    }
                }
                a{
                    font-size: 14px;
                    line-height: 16px;
                    float: right;
                    position: relative;
                    padding-right: 15px;
                    text-decoration: none;
                    transition: $time;
                    &::after{
                        content: '';
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-top: 2px solid;
                        border-right: 2px solid;
                        transform: rotate(45deg);
                        position: absolute;
                        right: 3px;
                        top: 4px;
                    }
                    
                    &:hover{
                        text-decoration: underline;
                    }
                }
                &__blocks{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    div{
                        border: 1px solid #CDCDCD;
                        width: 170px;
                        height: 120px;
                        border-radius: 5px;
                        background-size: cover;
                        background-position: center;
                        background-color: #fff;
                        margin-bottom: 20px;
                    }
                }
                &-left{
                    padding-right: 20px;
                    p{
                        color: #fff;
                        svg{
                            fill: #75AA39;
                            opacity: 0.5;
                        }
                    }
                    a{
                        color: #fff;
                        &::after{
                            border-color: #fff;
                        }
                    }
                }
                &-right{
                    padding-left: 20px;
                    p{
                        color: $color-text;
                        svg{
                            fill: #F5F5F5;
                            opacity: 0.5;
                        }
                    }
                    a{
                        color: $color-primary;
                        &::after{
                            border-color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width : 1199px) {
    .index-section-advantages .advantages-wrapper__block {
        min-width: 245px;
        padding: 0 35px;
        margin: 0px auto 25px;
    }
}
@media (max-width : 992px) {
    .index-section-partners .partners-content__blocks div{
        margin: 0 auto 15px;
    }
    .index-section-partners .partners-content a{
        float: none;
        text-align: center;
        display: block;
        padding-right: 0;
    }
    .index-section-partners .partners-content a::after{
        display: none;
    }
}

@media (max-width : 768px) {
    .index-section__header p{
        font-size: 24px;
        line-height: 28px;
    }
    .index-section__header a{
        display: none;
    }
    .index-section__mobile-more{
        display: block;
    }
}

@media (max-width : 576px) {
    .index-section__header p img{
        margin-top: -4px;
        margin-right: 8px;
    }
    .index-section__header{
        margin: 30px 5px 15px;
    }
    .index-section-partners .partners-bg{
        display: none;
    }
    .index-section-partners .partners-content-left{
        padding: 25px 15px 18px;
        background-color: $color-primary;
        margin-bottom: 18px;
        margin: 0 -15px 25px;
    }
    .index-section-partners .partners-content-right{
        padding-left: 0;
    }
    .index-section-partners .partners-content p{
        font-size: 28px;
        margin-bottom: 15px;
    }
    .index-section-partners .partners-content p svg{
        width: 75px;
        height: 75px;
        right: 115px;
        top: -15px;
    }
    .index-section-partners{
        padding-top: 0;
    }
}