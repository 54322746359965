.header {
    position: relative;
    background-color: $color-bg;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    z-index: 9;
    &-top {
        display: flex;
        flex-direction: row;
        padding: 12px 0 5px;
    }
    &-phones{
        display:flex;
        flex-direction: column;
    }
    &-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 45px;
        &__left{
            flex: 1 0;
        }
        &__left,
        &__right{
            display: flex;
            flex-direction: row;
            height: 100%;
        }
        &__right{
            justify-content: flex-end;
            width: 140px;
        }
    }
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 45px;
        left: 0;
        background-color: #999999;
    }
    &-mobile{
        display: none;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 0;
        &__left,
        &__right{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        &__left{
            .header-logo{
                margin-left: 10px;
            }
        }
    }
}

@media (max-width: 1199px) {
    .header::after,
    .header-top,
    .header-bottom{
        display: none !important;
    }
    .header-mobile{
        display: flex;
    }
}

@media (max-width: 479px) {
    .mobile-menu-button{
        padding: 0 5px !important;
    }
    .header-mobile__left .header-logo{
        align-items: center;
        img{
            width: 35px;
            height: 35px;
        }
        span{
            b{font-size: 24px;}
            i{font-size: 11px;}
        }
    }
    .mobile-search-trigger,
    .cart-button__mobile{
        margin: 10px !important;
    }
}
