
.invalid-feedback {
	display: block;
    position: absolute;
    top: 97%;
    width: 100%;
    margin-top: 0 !important;
    font-size: 80%;
    color: #dc3545;
}

i.modal_bg{
	display: none;
	position: fixed;
	z-index: 8999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	&.opened{
		display: block;
	}
}
a.modal_close{
	display: block;
	position: absolute;
	z-index: 205;
	top: 10px;
	right: 10px;
	width: 18px;
	height: 18px;
	// opacity: 0.4;
	transition: .3s;
	&:after, &:before{
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 2px;
		background-color: #fff;
		border-radius: 3px;
	}
	&:after{
		transform: rotate(45deg);
	}
	&:before{
		transform: rotate(-45deg);
	}
	&:hover{
		opacity: 1;
	}
}
.custom_modal{
	display: block;
	position: fixed;
	z-index: 9000;
	top: 100px;
	left: 0;
	right: 0;
	margin: auto;
	width: 320px;
	max-width: 100%;
	max-height: 100%;
	border-radius: 4px;
	background-color: #fff;
	transform: translateY(-300%);
	transition: .3s;
	&.opened{
		transform: translateY(0%);
	}
	b.modal_title{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-weight: 500;
		font-size: 28px;
		line-height: 30px;
		text-align: center;
		color: #FFFFFF;
		width: 100%;
		height: 105px;
		background-color: $color-primary
	}
	form{
		&.success{
			.modal_close{
				&:after, &:before{
					background-color: #86BA4B;
				}
			}
		}
		.moda-group-wrapper{
			padding: 25px 30px;
		}
		.modal-group{
			.input-wrapper{
				display: flex;
			}
			margin-bottom: 20px;
			position: relative;
			span{
				display: flex;
				flex: 0 0 50px;
				justify-content: center;
				align-items: center;
				border: 1px solid #E5E5E5;
				border-radius: 5px;
				height: 50px;
				margin-right: 5px;
				b{
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					color: #757582;
				}
				&+input{
					width: 205px !important;
				}
			}
			textarea,
			input:not([type=submit]){
				outline: none;
				padding: 15px;
				font-family: Roboto !important;
				font-size: 16px;
				line-height: 19px;
				color: #9B9B9B;
				border: 1px solid #E5E5E5;
				border-radius: 5px;
				resize: none;
				width: 100%;
			}
		}
		.modal-button{
			font-weight: bold;
			font-size: 18px;
			line-height: 21px;
			color: #FFFFFF;
			width: 100%;
			height: 50px;
			background: #86BA4B;
			border-radius: 50px;
			outline: none !important;
			border: none;
			transition: $time;
			margin-bottom: 10px;
			cursor: pointer;
			&:hover{
				background-color: #75AA39;
			}
		}
		.acceptance, .acceptance a{
			font-size: 14px;
			line-height: 16px;
			color: #9B9B9B;
			a{
				text-decoration: underline;
				transition: $time;
				&:hover{
					text-decoration: none;
				}
			}
		}
		&.load{
			position: relative;
			.load{
				display: block;
				background-color: rgba(255,255,255,0.95);
			}
		}
		&.success{
			.success{
				display: flex;
				background-color: rgba(255,255,255,1);
			}	
		}

		.load{
			img{
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				width: 100px;
				height: 100px;
			}
		}
		.success{
			flex-direction: column;
			align-items: center;
			justify-content: center;
			b{
				display: block;
				color: #262626;
				font-size: 28px;
				font-weight: 700;
				line-height: 30px;
				text-align: center;
				padding-top: 20px;
			}
			img{
				display: block;
				margin: 0 auto;
			}
			> svg{
				fill: $color-primary;	
			}
		}
		.load, .success{
			display: none;
			position: absolute;
			z-index: 200;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
.acceptance_checkbox{
	display: flex;
	align-items: center;
	margin: 10px 0 0;
	cursor: pointer;
	&:hover{
		.text{
			a{
				color: $color-general;
			}
		}
		.new_checkbox{
			border-color: $color-general;
		}
	}
	input{
		display: none;
	}
	input:checked + .new_checkbox{
		background-color: $color-general;
		border-color: $color-general;
	}
	.new_checkbox{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 14px;
		height: 14px;
		margin-right: 10px;
		border: 1px solid #c0c0c0;
		background-color: #fff;
		border-radius: 3px;
		transition: .2s;
		> svg{
			fill: #fff;
			transition: .2s;
		}
	}
	.text{
		display: block;
		color: #9b9b9b;
		font-size: 13px;
		font-weight: 400;
		transition: .2s;
		a{
			color: #9b9b9b;
			text-decoration: underline;
		}
	}
}

@media (max-width: 1199px) {
	.custom_modal {
		top: 10px;
		max-height: calc(100% - 20px);
		overflow-y: scroll;
	  }
}