.slider-section{
	
}
.bootslider{
	height: 520px;
	&-slide{
		background-position: center;
		background-size: cover;
		height: 100%;
		&__content{
			max-width: 1200px;
			height: 100%;
			margin: 0 auto;
			display: flex;
    		flex-direction: column;
    		justify-content: center;
			p{
				font-size: 52px;
				line-height: 60px;
				color: $color-text;
				margin-bottom: 15px;
			}
			span{
				font-size: 26px;
				line-height: 30px;
				color: $color-text;
			}
		}
	}
	&-arrow{
		width: 50px;
		height: 50px;
		border: 1px solid $color-primary;
		position: absolute;
    	top: calc(50% - 25px);
    	z-index: 9;
    	transition: $time;
    	cursor: pointer;
		&::after{
			content: '';
			display: block;
			width: 15px;
			height: 15px;
			position: absolute;
    		top: calc(50% - 7px);
			border-top: 1px solid $color-primary;
			border-right: 1px solid $color-primary;
			transition: $time;
		}
		&__prev{
			left: 0;
			&::after{
				transform: rotate(-135deg);
				left: calc(50% - 3px);
			}
		}
		&__next{
			right: 0;
			&::after{
				transform: rotate(45deg);
				right: calc(50% - 5px);
			}
		}
		&:hover{
			background-color: $color-primary;
			&::after{
				border-color: #fff;
			}
		}
	}
	.slick-list,
	.slick-track,
	.slick-slide,
	.slick-slide>div{
		height: 100%;
	}
}

.slide-link{
	border-radius: 15px;
	max-width: 110px;
	padding: 5px 10px;
	margin: 20px 0;
	display:inline;
	background-color: #86BA4B;
	color: white;
}
.slide-link:hover{
	background-color: #75AA39;
	color:white;
}

.slick-dots{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 25px;
	li{
		button{display: none;}
		width: 15px;
		height: 15px;
		background-color: #CDCDCD;
		border-radius: 50%;
		margin: 0 5px;
		transition: $time;
		cursor: pointer;
		&.slick-active{
			background-color: $color-primary;
		}
	}
}
@media (max-width : 1199px) {
	.bootslider{
		height: 390px;
	}
	.bootslider-arrow{
		display: none !important;
	}
	.bootslider-slide__content{
		max-width: 960px;
	}
}
@media (max-width : 991px) {
	.bootslider {
	    height: 240px;
	}
	.bootslider-slide__content{
	    max-width: 540px;
	}
	.bootslider-slide__content p {
	    font-size: 36px;
	    line-height: 40px;
	}
	.bootslider-slide__content span {
	    font-size: 18px;
	    line-height: 24px;
	}
}
@media (max-width : 575px) {
	.bootslider-slide__content{
    	padding: 0 15px;
    }
}
@media (max-width : 479px) {
	.bootslider {
	    height: 200px;
	}
	.bootslider-slide__content p {
	    font-size: 26px;
	    line-height: 30px;
	    margin-bottom: 8px;
	}
	.bootslider-slide__content span {
	    font-size: 14px;
	    line-height: 18px;
	}
	.bootslider .slick-dots li{
		width: 10px;
		height: 10px;
	}
}
