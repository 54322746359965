.product-options{
	background-color: #FFFFFF;
	border: 1px solid #E5E5E5;
	border-radius: 5px;
	&__head{
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #E5E5E5;
		padding: 3px 20px;
		div{
			span{
				font-weight: 500;
				font-size: 13px;
				line-height: 15px;
				color: #999999;
				opacity: 0.99;
			}
		}
	}
	.col-size{flex-basis: 108px;}
	.col-color{flex-basis: 160px;}
	.col-price{flex-basis: 130px;}
	.col-count{flex-basis: 100px;}
	.col-add{flex: 1 0 auto;}

	&__body{
		form{
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 0px 20px;
			padding: 12px 0;
			border-bottom: 1px solid #E5E5E5;
			&:last-child{
				border-bottom: none;
			}
		}
		.col-size{
			padding-left: 17px;
			font-size: 18px;
			line-height: 22px;
			color: $color-text;
		}
		.col-color{
			height: 42px;
		}
		.col-price{
			b{
				display: block;
				font-weight: 500;
				font-size: 18px;
				line-height: 22px;
				color: $color-text;
			}
			strike{
				display: inline-block;
				font-size: 14px;
				line-height: 22px;
				color: #999999;
			}
			&.sale{
				b{
					color: #D95652;
				}
			}
		}
		.col-add{
			button{
				background-color: $color-primary;
				border-radius: 50px;
				font-weight: 700;
				font-size: 16px;
				line-height: 19px;
				color: #FFFFFF;
				width: 100px;
				height: 40px;
				transition: $time;
				outline: none;
				border: none;
				float: right;
				cursor: pointer;
				position: relative;
				&:hover{
					background-color: #75AA39;
				}
				&.added{
					&::after{
						content: 'Добавлено';
						display: flex;
						align-items: center;
						justify-content: center;
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						font-weight: 700;
						font-size: 16px;
						line-height: 19px;
						top: 0;
						border-radius: 50px;
						background-color: #75AA39;
					}
				}
			}
		}
	}
}

@media(max-width: 767px) {
	.product-options .col-size {
	    flex-basis: 80px;
	}
	.product-options .col-color {
	    flex-basis: 100px;
	}
	.product-options .col-price {
	    flex-basis: 95px;
	}
	.product-options__body .col-add button{
		width: 90px;
		height: 35px;
		font-size: 14px;
	}
	.product-options__body .col-add button.added{
		&::after{
			font-size: 14px;
		}
	}
	.product-amount{
		height: 35px;
	}
}

@media (max-width: 575px){
	.product-options__head{
		display: none;
	}
	.product-options__body form{
		flex-wrap: wrap;
	}
	.product-options .col-size {
	    flex-basis: 100%;
	    margin-bottom: 8px;
	    padding-left: 0;
	    &::before{
	    	display: inline-block;
	    	font-size: 18px;
		    line-height: 22px;
		    color: #333333;
	    	content: 'Размер:';
	    	position: relative;
	    }
	}
	.product-options .col-color,
	.product-options .col-price,
	.product-options .col-count,
	.product-options .col-add{
		flex-basis: 25%;
	}
}

@media (max-width: 439px){
	.product-options .col-color,
	.product-options .col-price,
	.product-options .col-count,
	.product-options .col-add{
		flex-basis: 50%;
	}
	.product-options .col-count,
	.product-options .col-add{
		margin-top: 10px;
	}
	.product-options__body .col-add button{
		float: left;
	}
}