.category-card {
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    height: 220px;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 30px;
    padding: 30px;
    span{
    	font-weight: 500;
		font-size: 24px;
		line-height: 30px;
		color: $color-text;
		transition: $time;
		position: relative;
        display: block;
        max-width: 180px;
		&::after{
			content: '';
			position: absolute;
			display: block;
			height: 3px;
			width: 0px;
			bottom: -10px;
			background-color: $color-primary;
			transition: $time;
		}
    }
    &:hover{
    	span{
    		color: $color-primary;
    		&::after{
    			width: 60px;
    		}
    	}
    }
}


@media (max-width: 575px){
    .index-section-category{
        padding: 30px 0 0;

    }
    .category-card{
        height: 165px;
        padding: 0;
        padding-top: 0;
        margin-bottom: 15px;
        background-position: right bottom;
        overflow: hidden;
        span {
            padding: 7px;
            background-color: rgba(255, 255, 255, 0.548);
            max-width: 100%;
        }
    }
}

@media (max-width: 479px){
    .category-card span{
        font-size: 20px;
        line-height: 24px;
        max-width: 100%;
    }
}