.page-title {
	display: block;
	font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
    padding-bottom: 12px;
    &_small {
        font-size: 20px;
        line-height: 22px;
        padding: 0 0 16px;
    }
}

@media (max-width: 565px) {
	.page-title {
		line-height: 32px;
        font-size: 26px;
        
        &_small {
            font-size: 18px;
            line-height: 20px;
        }
	}
}
