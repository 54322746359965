.page {
	background-color: $color-bg;
	&-section{
		&__breadcrumbs{
			background-color: $color-primary;
		}
		&__content{
			.container{
				background-color: #FAFAFA;
				padding: 30px 15px 60px;
				position: relative;
				&:before, &:after{
					content: '';
					direction: block;
					position: absolute;
					top: 0px;
					background-color: #FAFAFA;
					width: 15px;
					height: 100%;
				}
				&:before{
					left: -15px;
				}
				&:after{
					right: -15px;
				}
			}
		}
	}
}


@media (max-width: 575px) {
	.page-section__content .container{
		padding: 15px 15px 40px;
	}
	.page-section__content .container:before,
	.page-section__content .container:after{
		display: none;
	}
}