.cart {
    &-section{
        .container{
            background-color: transparent;
            &::before, &::after{
                display: none;
            }
        }
    }
    &-info{
        background-color: #fff;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        margin-bottom: 30px;
        padding: 22px 15px 0px;
        p{
            margin-bottom: 14px;
            span{
                display: block;
                margin-bottom: 8px;
                font-size: 16px;
                line-height: 24px;
                color: #333333;
                b{
                    color: #D95652;
                }
            }
        }
    }
    &-table {
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        overflow: hidden;

        &-head {
            display: flex;
            align-items: center;
            height: 30px;
            border-bottom: 1px solid $color-border;
            padding-left: 20px;

            &__title {
                font-size: 13px;
                color: $color-caption;
            }
        }

        &-row {
            display: flex;
            width: 100%;
            height: 100%;
        }

        &-col {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 100%;

            &_price {
                flex: 0 0 auto;
                width: 170px;
            }

            &_count {
                flex: 0 0 auto;
                width: 170px;
            }

            &_total {
                flex: 0 0 auto;
                width: 165px;
            }

            &_delete {
                flex: 0 0 auto;
                width: 50px;
            }
            &_category{
                width: 200px;
                flex: 0 0 auto;
            }
        }
    }

    &-desc {
        margin-top: 33px;
        display: flex;
        flex-direction: row;
        align-items: center;
        i{
            display: flex;
            border-radius: 50%;
            font-style: normal;
            border: 2px solid #7E7224;
            color: #7E7224;
            font-weight: 700;
            font-size: 16px;
            width: 27px;
            height: 27px;
            flex: 0 0 27px;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
        }
        span{
            font-size: 15px;
            line-height: 20px;
            color: #7E7224;
        }
    }

    &-order {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 28px;

        &-btn {
            min-width: 270px;
            height: 50px;
            margin-left: 30px;
            background-color: $color-primary;
            border-radius: 50px;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            transition: $time;
            &:not(.disabled):hover{
                background-color: #75AA39;
                color: #fff;
            }
            &.disabled{
                background-color: #a5a4a4;
                border-color: #a5a4a4;
                &:hover{
                    color: #fff;
                }
            }
        }
    }

    &-text {
        display: inline-flex;
        align-items: flex-end;
        font-weight: 500;
        color: $color-text;
    }

    &-total, &-currency {
        font-weight: 700;
        font-size: 30px;
        margin-left: 5px;
        margin-bottom: -2px;
    }
}

@media(max-width: 1199px) {
    .cart {
        &-order {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 20px;

            &-btn {
                margin-top: 10px;
                margin-left: 0;
            }
        }
    }
}

@media(max-width: 767px) {
    .cart {
        &-table {
            &-head {
                display: none;
            }

            &-row {
                flex-direction: column;
            }

            &-col {
                flex: 0 1 auto;
                align-items: center;
                width: 100%;
                height: auto;
                padding: 5px 15px;

                &_product {
                    padding: 15px 15px 5px;
                }

                &_delete {
                    padding: 0;
                }
            }
        }
    }
}
.cart-info-mobile{
    margin-top: 30px;
    display:none;
}
@media(max-width : 768px) {
    .cart-info-desktop{
        display: none;
    }
    .cart-info-mobile{
        display: block;
    }
}
