.search-form {
    flex: 1 0 auto;
	margin-left: 50px;
	margin-right: 40px;
	height: 40px;
	position: relative;
	input{
		width: 100%;
		height: 100%;
		border-radius: 50px;
		border: none;
		background-color: #fff;
		padding: 0 40px 0 10px;
		font-size: 14px;
		line-height: 16px;
		color: #999999;
		outline: none;
	}
	button{
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 7px;
		right: 5px;
		width: 25px;
		height: 25px;
		border: none;
		background: $color-primary;
		cursor: pointer;
		border-radius: 50%;
		transition: $time;
		outline: none;
		> svg{
			fill: #fff;
			width: 12px;
			height: 12px;
			margin-top: -2px;
    		margin-right: 0px;
		}
		&:hover{
            background-color: #75AA39;
        }
	}
	&__ajax-search{
		padding: 10px 20px 0;
		
		> ul{
			display: none;
			border-radius: 4px;
			border: 1px solid #ccc;
			background-color: #fff;
			position: absolute;
			z-index: 200;
			left: 0;
			top: 100%;
			width: 100%;
			max-height: 500px;
			overflow-y: scroll;
			li{
				a, span{
					display: block;
					padding: 8px 20px;
					color: #9b9b9b;
					font-size: 15px;
					font-weight: 600;
					line-height: 21px;
					background-color: #f5f5f5;
					transition: .2s;
					&:hover{
						color: $color-primary;
					}
				}
			}

			ul{
				padding: 2px 0;
				li{
					a{
						
						padding: 5px 20px;
						color: #373a3c;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						background-color: transparent;
						&:hover{color: $color-primary;}
					}
				}
			}
		}
	}
}
