.product-slider{
	overflow: hidden;
	&__container{
		position: relative;
		border-radius: 4px;
		border: 1px solid #e5e5e5;
		background-color: #fff;
		overflow: hidden;
		height: 405px;
	}
	&__container-slide{
		display: flex !important;
		align-items: center;
		justify-content: center;
		padding: 15px;
		height: 405px;
		outline: none;
		img{
		    width: 100%;
    		height: 100%;
    		object-fit: contain;
		}
	}
	&__carousel{
		padding-top: 12px;
		overflow: hidden;
		height: 82px;
		.slick-list{
			margin: 0 -3px;
			.slick-track{
				min-width: 100%;
			    display: flex;
    			justify-content: center;
			}
		}
		.slick-slide{
		    margin: 0 5px;
    		width: 70px !important;
			&.slick-current{
				.product-slider__carousel-item{
					border-color: $color-primary;
				}
			}
		}
	}
	&__carousel-item{
		cursor: pointer;
		display: flex !important;
		align-items: center;
		justify-content: center;
		height: 70px;
		width: 70px !important;
		padding: 5px;
		outline: none;
		border: 1px solid #E5E5E5;
		border-radius: 5px;
		background-color: #fff;
		img{
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.slick-dots{
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 7px;
		width: 100%;
		li{
			&.slick-active{
				button{
					background-color: $color-primary;
				}
			}
			button{
				width: 11px;
				height: 11px;
				border-radius: 100%;
				font-size: 0;
				border: none;
				box-shadow: none;
				outline: none;
				margin: 0 6px;
				padding: 0;
				background-color: #dbdbdb;
			}
		}
	}
}

@media (max-width : 991px) {
	.product-slider{
		margin-bottom: 25px;
	}
}

@media (max-width : 767px) {
	.product-slider__container,
	.product-slider__container-slide{
		height: 320px;
	}
	.product-slider__carousel{
		display: none;
	}
}