.product-tabs{
	background: #FFFFFF;
	border: 1px solid #E5E5E5;
	border-radius: 5px;
	.nav-tabs{
		.nav-item{
			.nav-link{
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				color: $color-text;
				padding: 15px 0;
				margin: 0 30px;
				border: none !important;
				border-bottom: 4px solid transparent !important;
				transition: $time;
				&.active{
					background-color: transparent;
					border-bottom: 4px solid $color-primary !important;
					color: $color-primary;
				}
			}
		}
	}
}


.tab-pane{
	padding: 22px 30px 4px;
	&__text{
		.content-text{
			p{
				font-size: 16px;
				line-height: 24px;
				color: $color-text;
			}
		}
	}
	&__characteristic-row{
		display: flex;
		align-items: flex-start;
		padding: 8px 0;
		&:not(:last-child){
			border-bottom: 1px solid #e5e5e5;
		}
		&-title{
			display: block;
			width: 46%;
			min-width: 46%;
			color: #999;
			font-size: 13px;
		}
		&-value{
			display: block;
			color: #3a372e;
			font-size: 16px;
		}
	}
}


@media (max-width : 767px) {
	.nav-tabs{
		flex-direction: column;
		.nav-item{
			&:not(:last-child){
				border-bottom: 1px solid #dbdbdb;
			}
		}
		.nav-link{
			border: none !important;
			margin: 0 10px !important;
		}
	}
	.tab-pane{
		padding: 10px 10px;
	}
}

@media (max-width : 575px) {
	.nav-tabs{
		.nav-link{
			padding: 5px 10px;
			font-size: 15px;
			font-weight: 600;
		}
	}
	.tab-pane{
		&__characteristic-row{
			flex-direction: column;
			padding: 8px 0 0;
			&-title{
				width: 100%;
			}
			&-value{
				font-size: 14px;
			}
		}
	}
}