.footer {
	&-top{
		padding: 40px 0;
		&__logo{
			text-align: center;
			img{
				width: 75px;
				height: 75px;
				margin-bottom: 8px;
			}
			p{
				span{
					display: block;
					font-size: 18px;
					line-height: 21px;
					color: #1C4526;
				}
				b{
					display: block;
					font-family: "Times New Roman";
					font-size: 34px;
					line-height: 40px;
					color: #1C4526;
				}
			}
		}
		&__block{
			b{
				font-size: 13px;
				line-height: 15px;
				text-transform: uppercase;
				color: $color-text;
				margin-bottom: 20px;
			    letter-spacing: 0.4px;
				display: block;
			}
			ul{
				li{
					&>a{
						font-size: 14px;
						line-height: 20px;
						color: $color-text;
						display: inline-block;
    					padding: 4px 0;
    					transition: $time;
    					&:hover{
    						color: $color-primary;
    						text-decoration: underline;
    					}
					}
				}
			}
			p{
				margin-bottom: 10px;
				display: flex;
				svg{
					width: 15px;
					height: 15px;
					fill: $color-primary;
					margin-right: 10px;
					flex: 0 0 15px;
	    			margin-top: 3px;
				}
				span, a{
					display: block;
					font-size: 14px;
					line-height: 20px;
					color: $color-text;
				}
				a{
					margin-bottom: 11px;
					transition: $time;
					&:hover{
						color: $color-primary;
					}
				}
			}
			&.address{
				position: relative;
				height: 100%;
				&::before{
					content: '';
					width: 1px;
					height: 100%;
					background-color: #DDCFBE;
					position: absolute;
					left: -30px;
					top: 0;
				}
			}
		}
	}
	&-bottom{
		background-color: $color-bg;
		padding: 24px 0;
		&__wrapper{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		&__left{
			span, a{
				font-size: 14px;
				line-height: 16px;
				color: #999999;
				margin-right: 20px;
			}
			a{
				text-decoration-line: underline;
				transition: $time;
				&:hover{
					text-decoration: none;
				}
			}
		}
		&__right{
			a{
				font-size: 14px;
				line-height: 16px;
				color: #999999;
				$time: .2s;
				svg{
					width: 100px;
					height: 20px;
					fill: #999999;
					margin-left: 5px;
					$time: .2s;
				}
				&:hover{
					color: $color-primary;
					svg{
						fill: $color-primary;
					}
				}
			}
		}
	}
}


@media (max-width : 991px) {

}

@media (max-width : 767px) {
	.footer-top__logo{
		margin-bottom: 35px;
	}
	.footer-bottom__left span,
	.footer-bottom__left a{
		display: block;
		margin-right: 0;
		margin-bottom: 5px;
	}
}

@media (max-width : 575px) {
	.footer-top {
	    padding: 20px 0 0px;
	}
	.footer-top__block{
		max-width: 290px;
		margin: 0 auto 30px;
	}
	.footer-top__block.address::before{
		display: none;
	}
	.footer-bottom{
		padding: 15px 0 10px;
	}
	.footer-bottom__wrapper{
		flex-direction: column;
		max-width: 290px;
		margin: 0 auto;
	}
}