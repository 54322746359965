.banner{
    background-color: #faef9c;
}

.banner-content{
    display: flex;
    align-items: center;
    &-ico{
        width: 48px;
        height: 48px;
        margin-right: 10px;
    }
}

.banner-text{
    height: 91px;
    display: flex;
    align-items: center;
    &-text{
        font-size: 18px;
        line-height: 1em;
        font-weight: 500;
    }
}

@media (max-width: 1199px){
    .banner-text{
        &-text{
            font-size: 17px;
            line-height: .9em;
        }
    }
}

@media (max-width: 498px){
    .banner-text{
        height: 110px;
        &-text{
            font-size: 15px;
            line-height: .9em;
        }
    }
}

@media (max-width: 362px){
    .banner-text{
        height: 165px;
        display: flex;
        align-items: center;
        &-text{
            font-size: 16px;
            line-height: 1em;
            font-weight: 500;
        }
    }
}
