.product-photo{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 380px;
	border-radius: 4px;
	border: 1px solid #dbdbdb;
	
	> svg{
		display: none;
		fill: #efefef;
	}
	img{
		max-width: 85%;
		max-height: 85%;
	}
	&_none{
		background-color: #dbdbdb;
		> svg{
			display: block;
		}
		img{
			display: none;
		}
	}
}