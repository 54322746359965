.table {
	padding: 0 0 20px;
	margin: 0;
	&-responsive {
		border: 1px solid $color-border;
		border-top: 0;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	h3 {
		padding: 10px 20px;
		border: 0;
		letter-spacing: 0.3px;
		background-color: $color-general;
		border-radius: 4px 4px 0 0;
		color: #fff;
		font-size: 18px;
		font-weight: 700;
	}
	table{
		width: 100%;
	}
}
@media(max-width: 767px) {
	.table {
		padding: 5px 0;
		h3{
			font-size: 16px;
			line-height: 22px;
			padding: 10px 25px;
		}
	}
}